import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StepsRanking from './StepsRanking';
import SustainabilityRanking from './SustainabilityRanking';

const App = () => {
    const [activeComponent, setActiveComponent] = useState('steps');
    const [stepsData, setStepsData] = useState(null);
    const [pointsData, setPointsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [previousScroll, setPreviousScroll] = useState(0);
    const apiHost = process.env.REACT_APP_API_HOST;
    const tagId = process.env.REACT_APP_Tag;
    const stepsKpi = process.env.REACT_APP_KPI_Steps;
    const subDirectory = process.env.REACT_APP_Directory;
    const fetchStepsRanks = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    tenant: 'cme',
                }
            };
            const response = await axios.get(`${apiHost}/competition/steps/${tagId}`, {
                ...config,
                params: {
                    kpiId: stepsKpi,
                },
            });
            setStepsData(response.data);
        } catch (error) {
            console.error('Error fetching the steps ranks:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    const fetchPointsRanks = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    tenant: 'cme',
                }
            };
            const response = await axios.get(`${apiHost}/competition/points/${tagId}`, config);
            setPointsData(response.data);
        } catch (error) {
            console.error('Error fetching the points ranks:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    useEffect(() => {
        // Fetch data when the component is first mounted
        if (activeComponent === 'steps') {
            fetchStepsRanks();
        } else if (activeComponent === 'points') {
            fetchPointsRanks();
        }

        // Set up polling
        const intervalId = setInterval(() => {
            if (activeComponent === 'steps') {
                fetchStepsRanks();
            } else if (activeComponent === 'points') {
                fetchPointsRanks();
            }
        }, 300000); // Fetch every 60 seconds (adjust as necessary)

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [activeComponent]);
    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.scrollY;

            if (currentScroll < 20) {
                removeNavEffect();
            } else if (currentScroll > 0 && currentScroll < document.documentElement.scrollHeight - window.innerHeight) {
                if (currentScroll > previousScroll) {
                    addNavEffect();
                }
                setPreviousScroll(currentScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScroll]);

    const removeNavEffect = () => {
        document.querySelector('.header-main-wrap').classList.remove('scrolled');
    };

    const addNavEffect = () => {
        document.querySelector('.header-main-wrap').classList.add('scrolled');
    };
    const handleMenuClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div>
            <div className='header-main-wrap'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sticky-menu">
                                <div className='left-content'>
                                    <a href='https://www.ead.gov.ae'>
                                    <img src={`${subDirectory}/assets/images/ead-logo.svg`} alt="Logo" className='desktop-view'/>
                                    <img src={`${subDirectory}/assets/images/ead-logo-mobile.svg`} alt="Logo" className='mobile-view' />
                                    </a>
                                </div>
                                <div className='mid-content'>
                                    <div className="main-header">
                                        <img src={`${subDirectory}/assets/images/logo.svg`} alt="Logo" />
                                    </div>
                                    <div className="main-title">
                                    UNIVERSITY STANDINGS
                                    </div>
                                </div>
                                <div className='right-content'>
                                    
                                        <div className='img-wrap'><a href='https://www.instagram.com/baadr_ae?igsh=MWNxZGRramJ3NzNxaA==' ><img src={`${subDirectory}/assets/images/instagram.svg`}/> </a></div>
                                        <div className='description-wrap desktop-view'><a href='https://www.instagram.com/baadr_ae?igsh=MWNxZGRramJ3NzNxaA==' >FOLLOW US ON INSTAGRAM</a></div>
                                    
                                    <div className='mobile-view'><a href='https://onelink.to/4rew32'><img src={`${subDirectory}/assets/images/deeplink.svg`} /></a></div>
                                   
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                        <div className='col-md-12'>
                        <div className="main-menu">
                            <ul>
                                <li
                                    className={`menu-selector ${activeComponent === 'steps' ? 'active' : ''}`}
                                    onClick={() => handleMenuClick('steps')}
                                    data-attr="steps-rank"
                                >
                                    Daily Steps
                                </li>
                                <li
                                    className={`menu-selector ${activeComponent === 'points' ? 'active' : ''}`}
                                    onClick={() => handleMenuClick('points')}
                                    data-attr="points-rank"
                                >
                                    Sustainability Activities
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                <div className="row">
                    <div className="col-md-12">
                        {activeComponent === 'steps' && <StepsRanking ranks={stepsData} loading={loading} />}
                        {activeComponent === 'points' && <SustainabilityRanking ranks={pointsData} loading={loading} />}
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default App;
