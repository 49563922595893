import React from 'react';
const subDirectory = process.env.REACT_APP_Directory;
// Define the StepsRanking component
const StepsRanking = ({ ranks, loading }) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="dashboard-logo-wrapper">
                    <img src={`${subDirectory}/assets/images/steps-logo.svg`} className="steps-logo steps-rank" alt="Steps Logo" />
                </div>
                {loading && <div className="loader-wrapper"><div className="loader"></div></div>} {/* Show loader under the logo */}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="ranking-main-wrapper steps-rank">
                        {Array.isArray(ranks) && ranks.length > 0 && !loading ? (
                            ranks.map((rank, index) => (
                                <div className="ranking-wrap" key={index}>
                                    <div className="first-block-wrap">
                                        <div className="rank">{(rank.rank < 10 ? '0' : '') + rank.rank}</div>
                                        <div className="entity-wrap">
                                            <div className="entity-name">{rank.entityName}</div>
                                            <div className="entity-members">{rank.members} members</div>
                                        </div>
                                    </div>
                                    <div className="entity-numbers">
                                        {Number(rank.value).toLocaleString()} <span>{rank.unit}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            !loading && <div>No data available.</div> // Show a message when there's no data and not loading
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepsRanking;
